@font-face {
    font-family: 'Myfont';
    src:url(../resources/font/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'UTMLibelKT';
    src:url(../resources/font/Web01/UTMLibelKT.ttf);
}

@font-face {
    font-family: 'Roboto-bold';
    src:url(../resources/font/Roboto/Roboto-Thin.ttf);
}

// @font-face {
//     font-family: 'fontBold';
//     src:url(../resources/font/Roboto/);
// }

@font-face {
    font-family: 'fontLato';
    src: url(../resources/font/lato.ttf);
}


@font-face {
    font-family: 'fontLight';
    src: url(../resources/font/Roboto/Roboto-Light.ttf);
}

$font-size-base: 14px;
$font-family-base: 'Myfont';
$green: #1bb76e;
